import React from 'react';

import './page.css'

import FadeIn from 'react-fade-in';




const LoadingPage = (props) => {



    return(
        <div style={{overflow:'hidden'}}>

        
        <FadeIn delay={200} transitionDuration={300}>
            <body className='login-body' style={{overflow:'hidden'}}>
                

            </body>
        </FadeIn>
        </div>
        
    )
}

export default LoadingPage;