import { createSlice } from "@reduxjs/toolkit";
import { api } from "./api";

export const initialState = {
    loading: false,
    error:false,

    applications:[],

    search:"",

    toastType:null,
    toastValue:"Success!",
}

const applicationSlice = createSlice({
    name:'application',
    initialState,
    reducers: {
        setLoading: (state, action) => {state.loading = action.payload},
        setError: (state, action) => {state.error = action.payload},

        setApplications: (state, action) => {state.applications = action.payload},

        setSearch: (state, action) => {state.search = action.payload},

        setToastValue: (state, action) => {state.toastValue = action.payload},
        setToastType: (state, action) => {state.toastType = action.payload}
    }
})

export const {

    setLoading, setError, setApplications, setApplicationAmount, 
    setToastType, setToastValue, setSearch

} = applicationSlice.actions

export default applicationSlice.reducer;





export function getApplications() {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .get(`apps/staff`)
            .then((res) => {
                dispatch(setApplications(res.data))
                dispatch(setLoading(false))
                dispatch(setError(false))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setError(true))
            })
    }
}

export function addApplication(name) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .post(`apps/staff`, {
                name:name
            })
            .then((res) => {
                dispatch(setLoading(false))
                if(res.data.success) {
                    dispatch(getApplications())
                    dispatch(setToastType('success'))
                    dispatch(setToastValue(res.data.message))
                }
                else {
                    dispatch(setToastType('error'))
                    dispatch(setToastValue(res.data.message))
                }
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }
}

export function editApplication(id,message,isUp) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .patch(`apps/staff`, {
                id:id,
                message:message,
                is_up:isUp
            })
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(getApplications())
                dispatch(setToastType('success'))
                dispatch(setToastValue(res.data.message))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }
}

export function deleteApplication(id) {
    return async (dispatch) => {
        dispatch(setLoading(true))
        api
            .delete(`apps/staff?id=${id}`)
            .then((res) => {
                dispatch(setLoading(false))
                dispatch(getApplications())
                dispatch(setToastType('success'))
                dispatch(setToastValue(res.data.message))
            })
            .catch((err) => {
                dispatch(setLoading(false))
                dispatch(setToastType('error'))
                dispatch(setToastValue('Something went wrong...'))
            })
    }
}



