import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/auth'
import applicationReducer from './slices/applications'

export const store = configureStore({
    reducer: {
        auth:authReducer,
        application:applicationReducer

    }
})