import React from "react";
import { Box, IconButton, Flex, Text, Divider, Textarea, useDisclosure, Button, Checkbox, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

import { deleteApplication, editApplication } from "../../slices/applications";

import { HiOutlineCode, HiOutlineCheckCircle, HiOutlineClock, HiOutlineChat } from "react-icons/hi";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";

import './panels.css'



const EditApplicationModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()

    const { loading } = useSelector(state => state.application)


    const [message,setMessage] = React.useState('')
    const [isUp,setIsUp] = React.useState(props.app.is_up)

    const handleChangeMessage = (e) => setMessage(e.target.value) 
    const handleChangeIsUp = (e) => setIsUp(e.target.checked)

    const handleEditApp = () => dispatch(editApplication(props.app.id,message,isUp))

    return <>
        <IconButton size='sm' variant='outline' icon={<EditIcon color='blue.400' />} onClick={() => onOpen()} />



        <Modal isOpen={isOpen} onClose={() => onClose()} isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent  bg='primary.500' width='400px'>
                <ModalHeader>Edit Application</ModalHeader>
                <ModalCloseButton />

                <ModalBody>

                    <Checkbox marginBottom='20px' isChecked={isUp} onChange={(v) => handleChangeIsUp(v)}>Application is live</Checkbox>

                    <Textarea fontSize='xs' value={message} onChange={handleChangeMessage} placeholder='Downtime message'/>
                </ModalBody>
                <ModalFooter>
                    <Button isLoading={loading} leftIcon={<EditIcon />} onClick={() => handleEditApp()}>Update</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    
    </>
}
const ApplicationPanel = (props) => {

    const dispatch = useDispatch()



    const handleDeleteApp = () => dispatch(deleteApplication(props.app.id))

    return(
        <Box className='app-panel'>
            <Flex justifyContent='space-between' alignItems='top' marginBottom='20px'>
                <Flex alignItems='center' justifyContent='left'>
                    
                    <Box className='icon-container'>
                        <HiOutlineCode size='28px' color='#32b4ff' />
                    </Box>             
           
                    <Box>
                        <Text marginLeft='10px' maxW='160px' fontSize='xs' noOfLines={1}>{props.app.name}</Text>
                    </Box>
                </Flex>
                <Flex alignItems='center' justifyContent='right'>
                    <EditApplicationModal app={props.app} />
                    <Menu>
                        <MenuButton 
                            marginLeft='10px'
                            as={IconButton}
                            icon={<DeleteIcon color='red.400' />}
                            size='sm'
                            variant='outline'
                        />
                        <MenuList>
                            <MenuItem color='red.400' onClick={() => handleDeleteApp()}>
                                Permanently delete
                            </MenuItem>
                            <MenuItem>
                                Cancel
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
 
            </Flex>
            
            <Divider marginBottom='14px' />

            {props.app.is_up ? 
                <Flex alignItems='center' marginBottom='10px'>
                    <HiOutlineCheckCircle size='22px' color='#48BB78' />
                    <Text fontSize='xs' marginLeft='5px' color='green.400'>App is live</Text>
                </Flex> : 
                <Flex alignItems='center' marginBottom='10px'>
                    <HiOutlineClock size='22px' color='#F56565' />
                    <Text fontSize='xs' marginLeft='5px' color='red.400'>App is down</Text>
                </Flex>
                }

            <Flex alignItems='center' marginBottom='10px'>
                <HiOutlineChat size='22px' color='#7f7f7f' />
                <Text noOfLines={3} fontSize='xs' marginLeft='5px' color='textSecondary.500'>{props.app.message}</Text>
            </Flex>


        </Box>
    )
}

export default ApplicationPanel