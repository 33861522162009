import React from 'react';
import Header from '../components/header/Header'
import FadeIn from 'react-fade-in'
import { Box, Button, Input, SimpleGrid, Skeleton, Center, Text } from '@chakra-ui/react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from "@chakra-ui/react";


import { useToast, useDisclosure } from '@chakra-ui/react';
import { AddIcon, WarningIcon } from '@chakra-ui/icons';

import { useSelector, useDispatch } from 'react-redux';
import { addApplication, getApplications, setToastType } from '../slices/applications';

import ApplicationPanel from '../components/panels/ApplicationPanel';

const AddApplicationModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useDispatch()

    const { loading } = useSelector(state => state.application)


    const [appName,setAppName] = React.useState('')

    const handleAppNameChange = (e) => setAppName(e.target.value) 

    const handleCreateApp = () => dispatch(addApplication(appName))

    return <>
        <Button
            bg='white'
            borderRadius='8px'
            focusBorderColor="blue.500"
            size='sm'
            marginRight='5px'
            leftIcon={<AddIcon />}
            onClick={() => onOpen()}
        >
            App
        </Button>

        <Modal isOpen={isOpen} onClose={() => onClose()} isCentered scrollBehavior="inside">
            <ModalOverlay />
            <ModalContent  bg='primary.500' width='400px'>
                <ModalHeader>Add Application</ModalHeader>
                <ModalCloseButton />

                <ModalBody>
                    <Input placeholder='Application Name' value={appName} onChange={handleAppNameChange} />

                </ModalBody>
                <ModalFooter>
                    <Button isLoading={loading} leftIcon={<AddIcon />} onClick={() => handleCreateApp()}>Add</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    
    </>
}

const NavigationPage = () => {
    const toast = useToast()
    const dispatch = useDispatch()

    const { loading, error, search, applications } = useSelector(state => state.application)
    const { toastType, toastValue } = useSelector(state => state.application)


    const PageContent = () => applications.map((app) => {
        return(<ApplicationPanel app={app} />)
    })
    const LoadingContent = () => {
        return(
            <>
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
                <Skeleton height='240px' maxWidth='500px' borderRadius='8px' />
            </>
        )
    }

    const ErrorContent = () => {
        return(
            <>
                <Box>
                    <Center marginTop='30%'>
                        <WarningIcon fontSize='2xl' />
                    </Center>
                    <Center marginBottom='100px'>
                        <Text>Something went wrong...</Text>
                    </Center>
                </Box>
           
            </>
        
        )
    }


    const Content = () => {
        if(error) return <ErrorContent />
        else if(loading) return <LoadingContent />
        else return <PageContent />
    }

    React.useEffect(() => {
        if(toastType) toast({
            title:toastValue,
            status: toastType,
            duration: 4000,
            isClosable: true,
        })
        dispatch(setToastType(null))
    })

    React.useEffect(() => {
        dispatch(getApplications())
    }, [])



    return <Box className='page-container' overflowY='auto'>
        <FadeIn>
            <Header 
                title='Applications'
                rightElements={<>
                    <AddApplicationModal />
                </>}
            />
            <Box className='content-container'>
                <SimpleGrid
                    minChildWidth='300px'
                    spacing='10px'
                >
                    <Content />
                </SimpleGrid>

            </Box>
            
        </FadeIn>
        
    </Box>
}


export default NavigationPage;