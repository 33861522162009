import React from "react";
import { useSelector } from "react-redux";

import NavigationPage from "./pages/Navigation";
import LoginPage from "./pages/Login";
import LoadingPage from "./pages/Loading";

import { useColorMode } from "@chakra-ui/react";

import { useDispatch } from "react-redux";
import { checkAuth } from "./slices/auth";




const Page = () => {
    const { authLoading, authenticated } = useSelector(state => state.auth)

    if (authLoading) return <LoadingPage />
    else if (authenticated) return <NavigationPage />
    else return <LoginPage />
}

const Main = (props) => {
    const { colorMode, toggleColorMode } = useColorMode()
    const dispatch = useDispatch()


    React.useEffect(() => {
        if(colorMode === 'dark') toggleColorMode()

        dispatch(checkAuth())
    });




    

    return <Page />
    
}

export default Main;