import axios from "axios";



let url="https://api.status.capisoft.net/";
let timeout = 5000

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') url="http://localhost:8000/"

export let api = axios.create({
    baseURL: url,
    timeout: timeout,
    headers: {
        'Authorization': localStorage.getItem('access_token')
        ? 'Bearer ' + localStorage.getItem('access_token')
        : null,
        'Content-Type': 'application/json',
        'accept':'application/json'
    }
});

export let multiformAPI = axios.create({
    baseURL: url,
    timeout: timeout,
    headers: {
        'Authorization': localStorage.getItem('access_token')
        ? 'Bearer ' + localStorage.getItem('access_token')
        : null,
        'Content-Type': 'multipart/form-data',
        'accept':'application/json'
    }
});



export function resetAPI() {
    api = axios.create({
        baseURL: url,
        timeout: timeout,
        headers: {
            'Authorization': localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
            'Content-Type': 'application/json',
            'accept':'application/json'
        }
    })
    multiformAPI = axios.create({
        baseURL: url,
        timeout: timeout,
        headers: {
            'Authorization': localStorage.getItem('access_token')
            ? 'Bearer ' + localStorage.getItem('access_token')
            : null,
            'Content-Type': 'multipart/form-data',
            'accept':'application/json'
        }
    });
}