import React from 'react';

import { useDispatch, useSelector } from "react-redux";
import { checkAuth, loginUser } from '../slices/auth';

import './page.css'



import { Text, Input, Container, Button, InputGroup, InputRightElement, Divider, AlertIcon, Alert, Heading } from '@chakra-ui/react';
import FadeIn from 'react-fade-in';


const LoginPage = (props) => {

    const dispatch = useDispatch()
    const { loading, error, errorText } = useSelector(state => state.auth)

    const [username, setUsername] = React.useState('')
    const handleUsernameChange = (event) => setUsername(event.target.value)

    const [password, setPassword] = React.useState('')
    const handlePasswordChange = (event) => setPassword(event.target.value)

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);

    const [inputError,setInputError] = React.useState({
        username:false,
        password:false
    });



    const submit = () => {
        if(username === "" || !username.includes("@")) {
            setInputError({
                username:true,
                password:false
            })
            return;
        }
        if(password === "") {
            setInputError({
                username:false,
                password:true
            })
            return;
        }
        dispatch(loginUser(username,password));
    }

    return(
        <div style={{overflow:'hidden'}}>
        <FadeIn delay={200} transitionDuration={300}>



        <body className='login-body' style={{overflow:'hidden'}}>

            <div className='login-container'>


            <Heading marginTop='30px' fontSize='xl' color="black" align="center">CAPISOFT</Heading>
            <Text fontSize='sm' color="black" align="center">STATUS</Text>
            <Text fontSize='xl' color="#65656B" align="center">Admin Panel</Text>

                
                <Container maxW='md' marginTop="35px">
                    <Divider />
                </Container>

                {error ? 
                    <FadeIn>
                        <Container maxW='md' marginTop="35px" >
                            <Alert status='error' borderRadius='6px'>
                                <AlertIcon />
                                {errorText}
                            </Alert>
                        </Container>
                    </FadeIn>
                        : 
                    <></>
                }

                <Container maxW='md' marginTop="35px">
                    <Input 
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder='Email' 
                        variant='filled' 
                        size='md' 

                        focusBorderColor='blue.500'
                        errorBorderColor='red.500'
                        isInvalid={inputError.username}
                    />
                </Container>

                <Container maxW='md' marginTop="8px">
                    <InputGroup size='md'>
                        <Input
                            value={password}
                            onChange={handlePasswordChange}
                            
                            pr='4.5rem'
                            type={showPassword ? 'text' : 'password'}
                            placeholder='Password'
                            variant='filled'


                            focusBorderColor='blue.500'
                            errorBorderColor='red.500'                            
                            isInvalid={inputError.password}
                        />
                        <InputRightElement width='4.5rem'>
                            <Button h='1.75rem' size='sm' onClick={handleClickShowPassword}>
                                {showPassword ? 'Hide' : 'Show'}
                            </Button>
                        </InputRightElement>
                    </InputGroup>                
                </Container>

                <Container maxW='md' marginTop="36px" marginBottom="20px">
                    <Button  
                        bg='secondary.500'
                        width='100%' 
                        onClick={submit}    
                        isLoading={loading}
                    >
                        Login
                    </Button>                
                </Container>
            </div>
            
        </body>
        
        </FadeIn>
     
        </div>
    )
}

export default LoginPage;